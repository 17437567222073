import React from 'react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  createColumnHelper,
  ColumnFiltersState,
  getFilteredRowModel,
  getSortedRowModel,
} from '@tanstack/react-table';

import type { Row } from '@tanstack/react-table';

import type { AppealReviewSummary, AppealReviewStatus } from '../../api';
import { TableMultiselectFilter } from '../TableDropdownFilter/TableDropdownFilter';
import { type MultiselectAutocompleteOption } from 'design-system/src/components/MultiselectAutocomplete/MultiselectAutocomplete';
import {
  sortOfficeNames,
  stringsToDropdownOptions,
  getInitialMultiselectFilterValueFromCookie,
  getInitialTableColumnFilterValueFromCookie,
  updateTableColumnFilterValueCookie,
} from '../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import { noResultsCopy } from 'design-system/src/sharedCopy';
import { AuthContext } from '../../auth';
import {
  ClickableRow,
  FilterBar,
  FilterBarRow,
  FilterGroup,
  FilterLabel,
  SearchInput,
  Table,
  TableCell,
  TableContainer,
  TableHeader,
  HeaderSortingIndicator,
} from '../Table';

type UITaskStatus = 'Open' | 'Reviewed';

const columnHelper = createColumnHelper<AppealReviewSummary>();

interface AppealReviewTableProps {
  data: AppealReviewSummary[];
}

const appealReviewDateSortingFn = (a: Row<AppealReviewSummary>, b: Row<AppealReviewSummary>, columnId: string) => {
  return new Date(b.getValue(columnId)).getTime() - new Date(a.getValue(columnId)).getTime();
};

const appealReviewTableMultiSelectFilter = (row: Row<AppealReviewSummary>, columnId: string, filterValue: string[]) => {
  if (!filterValue.length) {
    // No filters are applied; return all rows
    return true;
  }

  // @ts-expect-error columnId will be one of the column heading keys
  return filterValue.includes(row.original[columnId]);
};

const AppealReviewsTable: React.FC<AppealReviewTableProps> = ({ data }) => {
  const navigate = useNavigate();
  const { appealReviewId } = useParams();
  const authContext = React.useContext(AuthContext);
  const userEmail = authContext.user?.email;

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([
    { id: 'status', value: 'Open' },
    {
      id: 'office_name',
      value: getInitialTableColumnFilterValueFromCookie('appeal_reviews', 'office_name', userEmail),
    },
    {
      id: 'prescriber_name',
      value: getInitialTableColumnFilterValueFromCookie('appeal_reviews', 'prescriber_name', userEmail),
    },
  ]);

  const getUIAppealReviewStatus = (status: AppealReviewStatus): UITaskStatus => {
    return status === 'unreviewed' ? 'Open' : 'Reviewed';
  };

  const prescriberStrings: string[] = [];
  const officeNameStrings: string[] = [];
  data.forEach((appealReview) => {
    prescriberStrings.push(appealReview.prescriber_name);
    if (appealReview.office_name) {
      officeNameStrings.push(appealReview.office_name);
    }
  });
  // TODO: should we instead pass sortOfficeNames into the TableMultiselectFilter?
  const sortedOfficeNameStrings = officeNameStrings.sort(sortOfficeNames);

  const columns = [
    columnHelper.accessor('created_at', {
      header: 'Review date',
      sortingFn: appealReviewDateSortingFn,
    }),
    columnHelper.accessor((row) => getUIAppealReviewStatus(row.status), {
      id: 'status',
      header: 'Status',
      enableGlobalFilter: false,
    }),
    columnHelper.accessor('patient_name', {
      header: 'Patient',
    }),
    columnHelper.accessor('prescriber_name', {
      header: 'Prescriber',
      filterFn: appealReviewTableMultiSelectFilter,
    }),
    columnHelper.accessor('drug_name', {
      header: 'Drug',
    }),
    columnHelper.accessor('prescription_recieved_at', {
      header: 'Rx date',
      sortingFn: appealReviewDateSortingFn,
    }),
    columnHelper.accessor('office_name', {
      header: 'Office',
      filterFn: appealReviewTableMultiSelectFilter,
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    enableGlobalFilter: true,
    globalFilterFn: 'includesString',
    initialState: { columnVisibility: { status: false } },
  });

  return (
    <TableContainer>
      <FilterBar>
        <FilterBarRow>
          <FilterGroup>
            <FilterLabel
              $active={table.getColumn('status')!.getFilterValue() == 'Open'}
              onClick={() => table.getColumn('status')!.setFilterValue('Open')}
            >
              Open
            </FilterLabel>
            <FilterLabel
              $active={table.getColumn('status')!.getFilterValue() == 'Reviewed'}
              onClick={() => table.getColumn('status')!.setFilterValue('Reviewed')}
            >
              Reviewed
            </FilterLabel>
          </FilterGroup>
          <SearchInput
            label="Search by name"
            hideLabel={true}
            onChange={(e) => table.setGlobalFilter((e.target as HTMLInputElement).value)}
          />
        </FilterBarRow>

        <FilterBarRow>
          <TableMultiselectFilter
            options={stringsToDropdownOptions(sortedOfficeNameStrings)}
            label={'Office'}
            initialValue={getInitialMultiselectFilterValueFromCookie('appeal_reviews', 'office_name', userEmail)}
            onChange={(_, value: MultiselectAutocompleteOption[]) => {
              const selectedValuesList = value.map((valueItem) => valueItem.value);
              table.getColumn('office_name')!.setFilterValue(selectedValuesList);
              updateTableColumnFilterValueCookie(value, 'appeal_reviews', 'office_name', userEmail);
            }}
          />
          <TableMultiselectFilter
            options={stringsToDropdownOptions(prescriberStrings)}
            label={'Prescriber'}
            initialValue={getInitialMultiselectFilterValueFromCookie('appeal_reviews', 'prescriber_name', userEmail)}
            onChange={(_, value: MultiselectAutocompleteOption[]) => {
              const selectedValuesList = value.map((valueItem) => valueItem.value);
              table.getColumn('prescriber_name')!.setFilterValue(selectedValuesList);
              updateTableColumnFilterValueCookie(value, 'appeal_reviews', 'prescriber_name', userEmail);
            }}
          />
        </FilterBarRow>
      </FilterBar>
      <Table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHeader key={header.id} onClick={() => header.column.toggleSorting()}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    <HeaderSortingIndicator
                      isSorted={header.column.getIsSorted()}
                      sortDirection={header.column.getNextSortingOrder()}
                    />
                  </TableHeader>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <ClickableRow
                $selected={row.original.id === appealReviewId}
                $new={row.original.status === 'unreviewed'}
                key={row.original.id}
                onClick={() => navigate(row.original.id)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    navigate(row.original.id);
                  }
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                ))}
              </ClickableRow>
            ))
          ) : (
            <tr>
              <TableCell colSpan={columns.length}>{noResultsCopy}</TableCell>
            </tr>
          )}
        </tbody>
      </Table>
    </TableContainer>
  );
};

export default AppealReviewsTable;
