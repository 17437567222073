import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import toast from 'react-hot-toast';
import { StandardErrorToast } from 'design-system/src/components/Toast/Toast';
import { Typography } from 'design-system/src/components/Typography/Typography';
import { ErrorMessage } from 'design-system/src/components/ErrorMessage/ErrorMessage';
import { client, FileType, NewCommentFile, FormFilesDocumentTypes, TaskComment, TaskEvent } from '../../api';
import { Sidebar, SidebarTopBar } from '../sidebar/Sidebar';
import React, { useContext, useState, useEffect } from 'react';
import { DrugPanel, PatientPanel, PrescriberPanel } from '../sidebar/SidebarPanels';
import { FileUploader, UploadedFile, UploadedFileRow } from './Files';
import styled from 'styled-components';
import { TaskContext } from './contexts';
import { TimelineItemList, TimelineItem } from '../TimelineItem/TimelineItem';
import { Button } from '../Button/Button';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';
import { PageLoadError } from 'design-system/src/components/PageLoadError/PageLoadError';
import { ItemViewTopNav } from '../tableItemDetailViewComponents/ItemViewTopNav';
import {
  BodyContainer,
  TopNavAndContent,
  ContentOuterWrapper,
  CenteredContent,
  Hairline,
  FormAndFooterContainer,
} from '../tableItemDetailViewComponents/Containers';
import {
  Form,
  FormOverlay,
  FormOverlayLoadingSpinner,
  ResponseInput,
  ButtonRow,
} from '../tableItemDetailViewComponents/FormComponents';
import { GeneralInfoTable } from '../GeneralInfoTable/GeneralInfoTable';
import { Toast } from 'design-system/src/components/Toast/Toast';
import { useNavigate } from 'react-router-dom';
import EnrollmentDeclineModal, { EnrollmentDeclineReason } from './EnrollmentDeclineModal';
import { tasksQueryKey } from '../../queries';

const FormContainer = styled.div`
  padding-bottom: 2rem;
`;

const StyledIframe = styled.iframe`
  margin: 1rem 0;
  border: 1px solid var(--border-gray);
  border-radius: var(--border-radius-medium);
  min-height: 1000px;
  height: 100%;
  width: 100%;
  overflow-y: hidden;
`;

const ActionContainer = styled(FormAndFooterContainer)`
  margin: 16px 0;
  padding: 16px;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-self: center;
`;

const DeclineButton = styled(Button)`
  border: 1px solid var(--border-gray);
  background: transparent;
  transition: background-color 0.2s ease-out;
  color: var(--black);
  align-self: flex-end;

  &:hover {
    background: var(--light-border-gray);
  }
`;

const TaskView: React.FC = () => {
  // Rather than using this ref and a single input, we want to switch
  // to react json schema forms to allow for more dynamic task defintions
  // Until we do that, we are using a less robust but quicker to imoplement
  // solution.
  const inputRef = React.useRef<HTMLTextAreaElement>(null);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  // Files that have been uploaded as part of composing a new comment
  const [newFilesUploaded, setNewFilesUploaded] = useState<NewCommentFile[]>([]);
  const [duplicateFileNameUploaded, setDuplicateFileNameUploaded] = useState<string | null>(null);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [declineReason, setDeclineReason] = useState('');
  const [declineLoading, setDeclineLoading] = useState(false);

  const { taskQuery, taskId, onTaskUpdate, updating, setUpdating } = useContext(TaskContext);
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  // NOTE: this logic is duplicated in NewRequestForm.tsx - please keep in sync
  const onFilesUpload = (filesToUpload: NewCommentFile[]) => {
    setDuplicateFileNameUploaded(null);
    let foundDuplicateFileName = false;

    // Error out if the user happened to choose a file with the same name (path) as one they've
    // already added. Normally it wouldn't matter, but we're using the file names as keys to track
    // the document types when sending them to the server for saving. If they upload another file
    // with the same name, they will both be uploaded with the same document type, which will
    // cause problems downstream.
    filesToUpload.map((fileToUpload) => {
      const fileNameAlreadyUploaded = newFilesUploaded.find((uploadedFile: NewCommentFile) => {
        return fileToUpload.file.name === uploadedFile.file.name;
      });
      if (fileNameAlreadyUploaded) {
        setDuplicateFileNameUploaded(fileToUpload.file.name);
        // We're using a local variable because the state won't update fast enough for us to use
        // that value
        foundDuplicateFileName = true;
        return;
      }
    });

    if (!foundDuplicateFileName) {
      setNewFilesUploaded([...newFilesUploaded, ...filesToUpload]);
    }
  };

  const onFileDelete = (filename: string, document_type: FileType) => {
    const newFilesUploadedCopy = [...newFilesUploaded];

    const fileIndex = newFilesUploadedCopy.findIndex((fileInfo) => {
      return fileInfo.file.name === filename && fileInfo.document_type === document_type;
    });

    if (fileIndex === -1) {
      toast.custom(() => <StandardErrorToast />);
      console.error("Couldn't delete file because we couldn't find it: ", filename);
    } else {
      newFilesUploadedCopy.splice(fileIndex, 1);
    }

    setNewFilesUploaded(newFilesUploadedCopy);
  };

  const declineTask = useMutation({
    mutationFn: async () => {
      setDeclineLoading(true);
      await client.post(`tasks/${taskId}/decline`, {
        decline_reason: declineReason,
      });
      setDeclineLoading(false);
    },
    onSuccess: () => {
      toast.custom(() => <Toast variant="success">Task declined.</Toast>);
      navigate('/tasks');
      queryClient.invalidateQueries({
        queryKey: tasksQueryKey(),
      });
    },
    onError: (error) => {
      toast.custom(() => <StandardErrorToast />);
      console.error(error);
      Sentry.captureException(error);
      setDeclineLoading(false);
      setShowDeclineModal(false);
    },
  });

  const submit = useMutation({
    mutationFn: async () => {
      // We need at least a message or a file to submit a new comment
      if (!inputRef.current?.value && !newFilesUploaded.length) {
        return;
      }

      const formData = new FormData();
      formData.append('message', inputRef.current?.value || '');

      if (newFilesUploaded.length) {
        const formFilesDocumentTypes: FormFilesDocumentTypes = {};

        newFilesUploaded.forEach((fileInfo) => {
          formData.append('files[]', fileInfo.file);

          // We can't add the document type to the file directly,
          // so we track the types in a separate object
          // and use the file names as keys.
          formFilesDocumentTypes[fileInfo.file.name] = fileInfo.document_type;
        });

        formData.append('form_files_document_types', JSON.stringify(formFilesDocumentTypes));
      }

      const res = await client.post(`tasks/${taskId}/submit`, formData);
      return res.data;
    },
    onMutate: () => {
      setUpdating(true);
    },
    onSuccess: () => {
      onTaskUpdate();
      setNewFilesUploaded([]);
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    },
    onError: (error) => {
      toast.custom(() => <StandardErrorToast />);
      setUpdating(false);
      console.error(error);
      Sentry.captureException(error);
    },
  });

  const handleDeclineSubmit = () => {
    declineTask.mutate();
  };

  const closeModal = () => {
    setShowDeclineModal(false);
  };

  useEffect(() => {
    const handleFormMessage = (messageEvent: MessageEvent) => {
      if (!messageEvent.data) return;
      console.log('messageEvent', messageEvent);

      try {
        if (!messageEvent.data.type) return;

        const event = messageEvent.data.type.split('embed.form.')[1];

        switch (event) {
          case 'completed':
            toast.custom(() => <Toast variant="success">Form signed.</Toast>);
            navigate('/tasks');
            queryClient.invalidateQueries({
              queryKey: tasksQueryKey(),
            });
            break;
          case 'exception':
            toast.custom(() => <Toast variant="error">Error, please try again.</Toast>);
            break;
        }
      } catch (e) {
        Sentry.captureException(e);
      }
    };

    window.addEventListener('message', handleFormMessage);
    return () => window.removeEventListener('message', handleFormMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let body;
  let modalToShow;
  if (taskQuery.isLoading || declineLoading) {
    body = <LoadingSpinner />;
  } else if (taskQuery.error) {
    body = <PageLoadError />;
  } else if (taskQuery.data) {
    const { task, drug, patient, prescriber } = taskQuery.data;

    const showSidebar = drug && patient && prescriber;

    modalToShow = (
      <EnrollmentDeclineModal
        closeModal={closeModal}
        declineReason={declineReason as EnrollmentDeclineReason}
        setDeclineReason={setDeclineReason}
        declineTask={declineTask}
        patient={patient}
        drug={drug}
        handleDeclineSubmit={handleDeclineSubmit}
      />
    );

    const getGeneralInfoTableData = () => {
      let patientName = null;
      let prescriberName = null;
      let dob = null;
      let prescribedOn = null;

      if (drug) {
        patientName = drug.patient_name;
        prescriberName = drug.prescriber_name;
        dob = drug.dob;
        prescribedOn = drug.prescription_recieved_at;
      } else if (task.first_name && task.last_name && task.dob) {
        // Old requests don't have rx data, but they should have these other values
        patientName = `${task.first_name} ${task.last_name}`;
        dob = task.dob || null;
      }

      return {
        patientName,
        prescriberName,
        dob,
        prescribedOn,
      };
    };

    const comments = (
      <TimelineItemList>
        {task.timeline &&
          task.timeline.map((item, index) => {
            let author;
            let timestamp;
            let message;
            let attachedFilesIds;
            if (item.item_type === 'comment') {
              const comment = item as TaskComment;
              timestamp = comment.created_at;
              author = comment.direction === 'inbound' ? comment.user : 'Tandem';
              message = comment.message;
              attachedFilesIds = comment.attachment_document_ids;
            } else {
              const event = item as TaskEvent;
              timestamp = event.event_timestamp;
              message = event.label;
            }
            return (
              <TimelineItem
                key={`task-item-${item.id}`}
                isLastCommentInList={index === task.timeline.length - 1}
                author={author}
                timestamp={timestamp}
                message={message}
                attachedFilesIds={attachedFilesIds}
              />
            );
          })}
      </TimelineItemList>
    );

    const commentInput = (
      <FormContainer>
        <Form>
          {updating && (
            <FormOverlay>
              <FormOverlayLoadingSpinner />
            </FormOverlay>
          )}
          <ResponseInput ref={inputRef} placeholder="Add a response..." />
          <UploadedFileRow>
            {newFilesUploaded.map((fileInfo) => {
              return (
                <UploadedFile
                  key={`${fileInfo.file.name}`}
                  onFileDelete={onFileDelete}
                  filename={fileInfo.file.name}
                  fileType={fileInfo.document_type}
                />
              );
            })}
          </UploadedFileRow>
          <ButtonRow>
            <FileUploader onFilesUpload={onFilesUpload} />
            <Button onClick={() => submit.mutate()} disabled={submit.isPending}>
              Submit
            </Button>
          </ButtonRow>
        </Form>
        <ErrorMessage visible={!!duplicateFileNameUploaded}>
          {`A file with the name ${duplicateFileNameUploaded || ''} has already been added. Please change the file name and try again.`}
        </ErrorMessage>
      </FormContainer>
    );

    const showCommentInput = task.status === 'completed' ? task.allow_reopening : true;

    body = (
      <BodyContainer>
        <TopNavAndContent>
          <ItemViewTopNav
            isSidebarCollapsed={isSidebarCollapsed}
            setIsSidebarCollapsed={setIsSidebarCollapsed}
            noSidebar={!showSidebar}
          />

          <ContentOuterWrapper>
            <CenteredContent>
              <Typography styledAs="h6" marginBottom="1rem">
                {task.type}
              </Typography>

              <GeneralInfoTable {...getGeneralInfoTableData()} />

              {task.timeline && comments}
              {showCommentInput && commentInput}
              {(task.timeline || showCommentInput) && <Hairline />}
            </CenteredContent>
          </ContentOuterWrapper>
          {task.embedded_document_url && task.status !== 'completed' && (
            <ActionContainer>
              <DeclineButton onClick={() => setShowDeclineModal(true)}>Decline enrollment</DeclineButton>
              <StyledIframe src={task.embedded_document_url} />
            </ActionContainer>
          )}
        </TopNavAndContent>
        {showSidebar && !isSidebarCollapsed && (
          <Sidebar>
            <SidebarTopBar />
            <DrugPanel drug={drug} />
            <PatientPanel patient={patient} />
            <PrescriberPanel prescriber={prescriber} />
          </Sidebar>
        )}
      </BodyContainer>
    );
  }

  return (
    <>
      {body}

      {showDeclineModal && modalToShow}
    </>
  );
};

export default TaskView;
