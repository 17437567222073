import styled from 'styled-components';
import moment from 'moment';
import { Typography } from 'design-system/src/components/Typography/Typography';
import { RenderHtmlString } from 'design-system/src/components/RenderHtmlString/RenderHtmlString';
import { Tooltip } from 'design-system/src/components/Tooltip/Tooltip';
import { formatStandardDate } from '../../utils';
import { AttachedFile } from '../tasks/Files';
import React from 'react';

export const StyledTimelineItemList = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledCommentHeader = styled.div`
  display: flex;
  align-items: center;
`;

const Dot = styled.div`
  width: 0.5rem;
  height: 0.5rem;
  margin-right: 12px;
  border-radius: 50%;
  background-color: var(--border-gray);
`;

const AuthorAndTimestamp = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
`;

const CommentTimestamp = styled(Typography)`
  color: var(--dark-gray);
`;

const CommentBody = styled.div<{ $lastComment: boolean }>`
  padding-left: 1rem;

  ${({ $lastComment }) => {
    return !$lastComment
      ? `
      border-left: 1px var(--border-gray) solid;
      margin-left: 3px;
    `
      : `
      // The last comment does not have the vertical line to the left
      margin-left: 4px;
    `;
  }}
`;

const CommentGap = styled.div<{ $lastComment: boolean }>`
  height: 22px;
  // The gap between the vertical line and the next dot
  margin-bottom: 6px;

  ${({ $lastComment }) => {
    // The gap after the last comment does not have the vertical line to the left
    return (
      !$lastComment &&
      `
      padding-left: 1rem;
      border-left: 1px var(--border-gray) solid;
      margin-left: 3px;
    `
    );
  }}
`;

const CommentMessage = styled(Typography)`
  margin-top: 0.5rem;
`;

const CommentFiles = styled.div`
  margin-top: 0.5rem;
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
`;

export type TimelineItemProps = {
  isLastCommentInList: boolean;
  author?: string;
  // Note: regular comments won't have an `action`
  action?: 'accepted appeal' | 'requested edits' | 'accepted prior authorization';
  // In ISO format. Will be reformatted to an 'ago' string
  timestamp: string;
  // Won't exist on action comments (e.g. appeal review acceptance)
  message?: string;
  attachedFilesIds?: string[];
};

export type TimelineItemListProps = {
  children: React.ReactElement[] | React.ReactElement;
};

/**
 * Wrapper for a list of TimelineItem`s (can be a "list" of 1 item).
 *
 * Includes the title "Activity" above the list.
 */
export const TimelineItemList = ({ children }: TimelineItemListProps) => {
  return (
    <div>
      <Typography styledAs="bodyLargeSpaceGrotesk" weight={500} marginBottom="1rem">
        Activity
      </Typography>
      <StyledTimelineItemList>{children}</StyledTimelineItemList>
    </div>
  );
};

/**
 * A comment in a list of comments on a task.
 *
 * Can also be used for items in a list of activity that appears like a comment (e.g. appeal review rejection) on a page for a task-like object (e.g. an appeal review).
 *
 * This component is intended to be used in conjunction with the TimelineItemList (also defined in this file).
 */
export const TimelineItem = ({
  isLastCommentInList,
  author,
  action,
  timestamp,
  message,
  attachedFilesIds,
}: TimelineItemProps) => {
  return (
    <div>
      <StyledCommentHeader>
        <Dot />
        <AuthorAndTimestamp>
          {author && <Typography>{author}</Typography>}
          {action && <Typography>{action}</Typography>}
          {(author || action) && <span aria-hidden>·</span>}
          <Tooltip content={formatStandardDate(timestamp)}>
            <CommentTimestamp styledAs="bodySmallDMSans">{moment(timestamp).fromNow()}</CommentTimestamp>
          </Tooltip>
        </AuthorAndTimestamp>
      </StyledCommentHeader>

      <CommentBody $lastComment={isLastCommentInList}>
        {message && (
          <CommentMessage>
            <RenderHtmlString>{message}</RenderHtmlString>
          </CommentMessage>
        )}

        {attachedFilesIds && !!attachedFilesIds.length && (
          <CommentFiles>
            {attachedFilesIds.map((fileId: string) => (
              <AttachedFile key={fileId} fileId={fileId} />
            ))}
          </CommentFiles>
        )}
      </CommentBody>

      <CommentGap $lastComment={isLastCommentInList} />
    </div>
  );
};
