import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { throttle } from 'lodash';
import { client } from '../../api';
import type { PriorAuthReviewFormData, PriorAuthReviewFormUpdateData } from '../../api';
import { Sidebar, SidebarTopBar } from '../sidebar/Sidebar';
import { ItemViewTopNav } from '../tableItemDetailViewComponents/ItemViewTopNav';
import {
  BodyContainer,
  TopNavAndContent,
  ContentOuterWrapper,
  CONTENT_OUTER_WRAPPER_PADDING,
  CenteredContent,
} from '../tableItemDetailViewComponents/Containers';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { DrugPanel, PatientPanel, PrescriberPanel } from '../sidebar/SidebarPanels';
import styled from 'styled-components';
import { PriorAuthReviewContext } from './contexts';
import { Button } from '../Button/Button';
import { TimelineItemList, TimelineItem } from '../TimelineItem/TimelineItem';
import { Typography } from 'design-system/src/components/Typography/Typography';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';
import toast from 'react-hot-toast';
import { Toast, StandardErrorToast } from 'design-system/src/components/Toast/Toast';
import { PageLoadError } from 'design-system/src/components/PageLoadError/PageLoadError';
import { pageLevelErrorCopy } from 'design-system/src/sharedCopy';
import { CustomPaForm } from './CustomPaForm/CustomPaForm';
import { GeneralInfoTable } from '../GeneralInfoTable/GeneralInfoTable';
import { focusVisibleStyles, standardTransitionStyles } from 'design-system/src/sharedStyles';
import { FormAndFooterContainer } from '../tableItemDetailViewComponents/Containers';

const FORM_FOOTER_HEIGHT = 66;

// the height is set programmatically in a useEffect hook
const StyledCustomPaFormViewer = styled.div<{ $hasIframe: boolean }>`
  overflow-y: ${({ $hasIframe }) => ($hasIframe ? 'hidden' : 'scroll')};
  border-radius: var(--border-radius-medium);
`;

const NoEmbedUrlErrorMessage = styled(Typography)`
  padding: 16px;
`;

const SupportLink = styled.a`
  // TODO(design-system): use Link styles when available
  color: var(--black);
  font-weight: 500;
  text-decoration-color: var(--medium-gray);
  // Pushes the underline away from the text
  text-underline-offset: 3px;
`;

const StyledIframe = styled.iframe`
  border: none;
  border-radius: var(--border-radius-medium);
  height: 100%;
  width: 100%;
  // the iframe contents scroll on their own inside the iframe
  // TODO: test this on ios
  overflow-y: hidden;
`;

const FormFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  height: ${FORM_FOOTER_HEIGHT}px;
  box-sizing: border-box;
  border-top: 1px solid var(--light-border-gray);
  background: var(--white);
  border-bottom-left-radius: var(--border-radius-medium);
  border-bottom-right-radius: var(--border-radius-medium);
  position: relative;
`;

const FooterLoadingSpinner = styled(LoadingSpinner)`
  width: 1.5rem;
  // Horizontally center the spinner regardless of the other
  // buttons in the footer
  position: absolute;
  left: calc(50% - 0.75rem);
`;

const AcceptButton = styled(Button)`
  display: flex;
  height: 2rem;
  padding: 0px 0.75rem;
  align-items: center;
  border-radius: var(--border-radius-medium);
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.15px;
  gap: 0.25rem;
  ${standardTransitionStyles}

  &:hover {
    // There's no design system color close to this
    background-color: #532e89;
    border-color: #532e89;
  }

  ${focusVisibleStyles}
`;

const ResetFormButton = styled(AcceptButton)`
  background-color: transparent;
  color: var(--dark-gray);
  border: 1px solid transparent;
  ${standardTransitionStyles}

  &:hover {
    background-color: var(--light-border-gray);
    border-color: var(--light-border-gray);
  }

  ${focusVisibleStyles}
`;

const PriorAuthReviewView: React.FC = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [attestationCheckboxIsChecked, setAttestationCheckboxIsChecked] = useState(false);
  const [showAttestationCheckboxError, setShowAttestationCheckboxError] = useState(false);
  const [userCompletedEmbedDoc, setUserCompletedEmbedDoc] = useState(false);

  const formContainerRef = useRef<HTMLDivElement>(null);
  const formAndFooterContainerRef = useRef<HTMLDivElement>(null);

  const attestationCheckboxErrorMessageId = 'prior-auth-form-attestation-checkbox-error-message';
  const formId = 'custom-pa-form-viewer';

  const queryClient = useQueryClient();

  const { priorAuthReviewQuery, priorAuthReviewId, onPriorAuthReviewUpdate, updating, setUpdating } =
    useContext(PriorAuthReviewContext);

  // first arg is an event because this is called by an event listener
  const setFormContainerHeight = (event: Event, timesCalled: number = 0) => {
    const formContainer = formContainerRef.current;
    if (formContainer) {
      const formContainerTop = formContainer.getBoundingClientRect().top;
      const footerHeight = priorAuthReviewQuery.data?.prior_auth_review.status !== 'submitted' ? FORM_FOOTER_HEIGHT : 0;
      formContainer.style.height = `${window.innerHeight - formContainerTop - footerHeight - CONTENT_OUTER_WRAPPER_PADDING}px`;
    } else if (timesCalled < 10) {
      // Limit to 10 tries to avoid infinite loop
      setTimeout(() => setFormContainerHeight(event, timesCalled + 1), 1000);
    }
  };

  const throttledSetFormContainerHeight = throttle(setFormContainerHeight, 1000);

  const handlePandadocMessage = (event: MessageEvent) => {
    const type = event.data?.type;
    const payload = event.data?.payload;

    if (type === 'session_view.document.completed') {
      setUserCompletedEmbedDoc(true);
    }
    if (type === 'session_view.document.exception') {
      console.error(payload);
      toast.custom(() => <StandardErrorToast />);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', throttledSetFormContainerHeight);
    window.addEventListener('message', handlePandadocMessage);

    return () => {
      window.removeEventListener('resize', throttledSetFormContainerHeight);
      window.removeEventListener('message', handlePandadocMessage);
    };
  }, []);

  useEffect(() => {
    setFormContainerHeight(new Event('resize'), 0);
  }, [formContainerRef.current]);

  const formQuery = useQuery<PriorAuthReviewFormData>({
    queryKey: ['customPaFormViewer', priorAuthReviewId],
    enabled: !!(priorAuthReviewQuery.data && !priorAuthReviewQuery.data.prior_auth_review.signature_required),
    queryFn: async () => {
      return client.get(`prior-auth-review/${priorAuthReviewId}/form`).then((res) => res.data);
    },
  });

  const saveQuestionAnswer = useMutation<PriorAuthReviewFormData, AxiosError, PriorAuthReviewFormUpdateData>({
    mutationFn: async (data) => {
      const res = await client.post(`prior-auth-review/${priorAuthReviewId}/form/answer`, data);
      return res.data;
    },
    onSuccess: () => {
      toast.custom(() => <Toast variant="success">Form saved</Toast>);
      return queryClient.invalidateQueries({ queryKey: ['customPaFormViewer', priorAuthReviewId] });
    },
    onError: () => {
      toast.custom(() => <StandardErrorToast />);
    },
  });

  const resetPriorAuthReviewForm = useMutation({
    mutationFn: async () => {
      const res = await client.post(`prior-auth-reviews/${priorAuthReviewId}/form/reset`);
      return res.data;
    },
    onSuccess: () => {
      toast.custom(() => <Toast variant="success">Form cleared</Toast>);
      return queryClient.invalidateQueries({ queryKey: ['customPaFormViewer', priorAuthReviewId] });
    },
    onError: () => {
      toast.custom(() => <StandardErrorToast />);
    },
  });

  const navigate = useNavigate();
  const acceptPriorAuthReview = useMutation<boolean, AxiosError, boolean>({
    mutationFn: async (signature_required) => {
      const res = await client.post(`prior-auth-reviews/${priorAuthReviewId}/form/submit`, {
        // attestation is part of the form the user signs
        attestation_submitted: attestationCheckboxIsChecked || signature_required,
      });
      return res.data;
    },
    onMutate: () => {
      setUpdating(true);
    },
    onSuccess: (signature_required) => {
      onPriorAuthReviewUpdate();
      navigate('/prior-auth-reviews');
      if (!signature_required) {
        return queryClient.invalidateQueries({ queryKey: ['customPaFormViewer', priorAuthReviewId] });
      }
    },
    onError: () => {
      toast.custom(() => <StandardErrorToast />);
      setUpdating(false);
    },
  });

  let body;
  if (priorAuthReviewQuery.isLoading) {
    body = <LoadingSpinner />;
  } else if (priorAuthReviewQuery.error) {
    body = <PageLoadError />;
  } else if (priorAuthReviewQuery.data) {
    const { prior_auth_review, patient, prescriber, drug } = priorAuthReviewQuery.data;

    const priorAuthReviewTitle = `${drug.name} (${patient.last_name})`;

    const getShowAcceptButton = () => {
      if (prior_auth_review.signature_required) {
        return userCompletedEmbedDoc;
      } else {
        return formQuery.data?.form_status === 'FORM_STATUS_COMPLETED';
      }
    };

    let formBody;
    if (prior_auth_review.signature_required) {
      if (prior_auth_review.embed_document_url) {
        formBody = <StyledIframe src={prior_auth_review.embed_document_url} />;
      } else {
        Sentry.captureException(new Error('No embed_document_url found for prior auth review with signature_required'));

        formBody = (
          // This should never happen and eng will be needed to unblock the user, hence
          // the custom error message with support's email
          <NoEmbedUrlErrorMessage styledAs="bodySmallDMSans">
            Error, please reach out to Tandem at{' '}
            <SupportLink href="mailto:support@withtandem.com">support@withtandem.com</SupportLink>
          </NoEmbedUrlErrorMessage>
        );
      }
    } else {
      if (formQuery.isLoading) {
        formBody = <LoadingSpinner />;
      }
      if (formQuery.error) {
        formBody = pageLevelErrorCopy;
      }
      if (formQuery.data) {
        formBody = (
          <CustomPaForm
            form={formQuery.data.form}
            reviewStatus={prior_auth_review.status}
            formId={formId}
            saveQuestionAnswer={saveQuestionAnswer.mutate}
            attestationCheckboxIsChecked={attestationCheckboxIsChecked}
            setAttestationCheckboxIsChecked={setAttestationCheckboxIsChecked}
            showAttestationCheckboxError={showAttestationCheckboxError}
            setShowAttestationCheckboxError={setShowAttestationCheckboxError}
            attestationCheckboxErrorMessageId={attestationCheckboxErrorMessageId}
            attestationSubmitted={prior_auth_review.attestation_submitted}
          />
        );
      }
    }

    body = (
      <BodyContainer>
        <TopNavAndContent>
          <ItemViewTopNav isSidebarCollapsed={isSidebarCollapsed} setIsSidebarCollapsed={setIsSidebarCollapsed} />

          <ContentOuterWrapper>
            <CenteredContent>
              {/* TODO: truncate this */}
              <Typography styledAs="h6" renderedAs="h2" marginBottom="1rem">
                {priorAuthReviewTitle}
              </Typography>

              <GeneralInfoTable
                patientName={`${patient.first_name} ${patient.last_name}`}
                dob={patient.date_of_birth}
                prescriberName={prescriber.name}
                prescribedOn={drug.prescription_recieved_at}
              />

              {/* This check is just to satisfy typescript. If the prior authorization
                  review has been reviewed, there should be reviewed_by and reviewed_at
                  properties */}
              {prior_auth_review.status === 'submitted' &&
                prior_auth_review.reviewed_by &&
                prior_auth_review.reviewed_at && (
                  <TimelineItemList>
                    <TimelineItem
                      key={'prior-authorization-review-acceptance'}
                      // This timeline always only has 1 item in it
                      isLastCommentInList={true}
                      author={prior_auth_review.reviewed_by}
                      timestamp={prior_auth_review.reviewed_at}
                      action="accepted prior authorization"
                    />
                  </TimelineItemList>
                )}

              <FormAndFooterContainer ref={formAndFooterContainerRef}>
                <StyledCustomPaFormViewer
                  $hasIframe={prior_auth_review.signature_required}
                  id={formId}
                  ref={formContainerRef}
                >
                  {formBody}
                </StyledCustomPaFormViewer>

                {prior_auth_review.status !== 'submitted' && (
                  <FormFooter>
                    {/* This div keeps the Accept button pushed to the right */}
                    <div>
                      {!prior_auth_review.signature_required && (
                        <ResetFormButton
                          onClick={() => {
                            if (!updating && !resetPriorAuthReviewForm.isPending) {
                              resetPriorAuthReviewForm.mutate();
                            }
                          }}
                          isLoading={resetPriorAuthReviewForm.isPending}
                          hasLightBackground={true}
                        >
                          Reset original answers
                        </ResetFormButton>
                      )}
                    </div>

                    {saveQuestionAnswer.isPending && <FooterLoadingSpinner />}

                    {getShowAcceptButton() && (
                      <AcceptButton
                        onClick={() => {
                          if (updating || resetPriorAuthReviewForm.isPending) {
                            return;
                          }
                          // manual attestation isn't needed if the pa review is signed by the user
                          if (attestationCheckboxIsChecked || prior_auth_review.signature_required) {
                            acceptPriorAuthReview.mutate(prior_auth_review.signature_required);
                          } else {
                            setShowAttestationCheckboxError(true);
                            // Scroll to the bottom of the form so the user can see the checkbox and error
                            const formContainer = document.getElementById(formId);
                            if (formContainer) {
                              formContainer.scrollTop = formContainer.scrollHeight;
                            }
                          }
                        }}
                        isLoading={updating}
                        aria-describedby={attestationCheckboxErrorMessageId}
                      >
                        Accept
                      </AcceptButton>
                    )}
                  </FormFooter>
                )}
              </FormAndFooterContainer>
            </CenteredContent>
          </ContentOuterWrapper>
        </TopNavAndContent>

        <Sidebar $isCollapsed={isSidebarCollapsed}>
          <SidebarTopBar />
          <DrugPanel drug={drug} />
          <PatientPanel patient={patient} />
          <PrescriberPanel prescriber={prescriber} />
        </Sidebar>
      </BodyContainer>
    );
  }

  return body;
};

export default PriorAuthReviewView;
