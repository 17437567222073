import React, { useEffect, useState } from 'react';
import { flexRender, getCoreRowModel, useReactTable, createColumnHelper, Table } from '@tanstack/react-table';
import {
  Table as TableComponent,
  TableCell,
  TableContainer,
  TableHeader,
  ClickableRow,
  FilterBar,
  FilterBarRow,
  SearchInput,
  FilterBadge,
  FilterBarRowSection,
  FilterGroup,
  FilterLabel,
} from '../Table';
import { TableMultiselectFilter } from '../TableDropdownFilter/TableDropdownFilter';
import type { MultiselectAutocompleteOption } from 'design-system/src/components/MultiselectAutocomplete/MultiselectAutocomplete';
import {
  getRxFilePreviewUrl,
  getRxFileDownloadUrl,
  OverallStatusFilter,
  RxTableResponseRow,
  StatusInfo,
} from '../../api';
import { useNavigate } from 'react-router-dom';
import { Pagination } from 'design-system/src/components/Pagination/Pagination';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';
import { PaginationTableProps } from 'design-system/src/components/Pagination/Pagination';
import { emptyTableCellCopy, noResultsCopy } from 'design-system/src/sharedCopy';
import { RxFilterOptions } from './useFetchRxTableFilters';
import { RxFilterValues } from '../../types';
import {
  getAddressString,
  getDropdownInitialValueFromSearchParams,
  getPrescriberDropdownInitialValueFromSearchParams,
  updateTableColumnFilterValueCookie,
} from '../../utils';
import { Tooltip } from 'design-system/src/components/Tooltip/Tooltip';
import EyeIcon from 'design-system/src/assets/icons/eye.svg?react';
import styled, { css } from 'styled-components';
import FilePreviewModal from 'design-system/src/components/FilePreviewModal/FilePreviewModal';
import { Typography } from 'design-system/src/components/Typography/Typography';
import { IconButton } from '../Button/Button';
import DownloadTray from 'design-system/src/assets/icons/downloadTray.svg?react';
import { breakpoints } from 'design-system/src/tokens/breakpoints';

const MergedRowItem = styled.td`
  text-align: center;
  padding: 2rem;
`;

const statusContainerStyles = css`
  color: var(--black);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: nowrap;
`;

const PreviewFileContainer = styled.a`
  ${statusContainerStyles}

  // We don't display the preview on mobile because users don't see the native pdf frame
  // with the download button on ios and can't scroll between pages
  @media (max-width: ${breakpoints.tabletMax}px) {
    display: none;
  }
`;

const DownloadFileContainer = styled.a`
  ${statusContainerStyles}
  text-decoration: none;

  // We display the download button instead of a preview button on mobile because users
  // don't see the native pdf frame with the download button on ios
  @media (min-width: ${breakpoints.smallDesktopMin}px) {
    display: none;
  }
`;

const columnHelper = createColumnHelper<RxTableResponseRow>();

const LoadingComponent = ({ table }: { table: Table<RxTableResponseRow> }) => {
  return (
    <tr>
      <MergedRowItem colSpan={table.getAllColumns().length}>
        <LoadingSpinner height="200px" />
      </MergedRowItem>
    </tr>
  );
};

const NoResultsComponent = ({ table }: { table: Table<RxTableResponseRow> }) => {
  return (
    <tr>
      <MergedRowItem colSpan={table.getAllColumns().length}>{noResultsCopy}</MergedRowItem>
    </tr>
  );
};

interface RxTableProps {
  data: Array<RxTableResponseRow>;
  loading: boolean;
  tableProps: PaginationTableProps & {
    submitSearchValue: (search: string) => void;
    filters: RxFilterOptions;
    setPrescriberFilter: (prescriberNamesList: string[]) => void;
    setOfficeFilter: (officeNamesList: string[]) => void;
    setDrugNameFilter: (drugNamesList: string[]) => void;
    setBiologicsFilter: (biologics: boolean) => void;
    setAwaitingOfficeFilter: (awaitingOffice: boolean) => void;
    setAwaitingPatientFilter: (awaitingPatient: boolean) => void;
    setOverallStatusFilter: (status: OverallStatusFilter) => void;
  };
  initialSearchValue?: string;
  initialFilterValues?: RxFilterValues;
  handleDownload: () => void;
  downloadLoading: boolean;
}

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
  gap: 0.5rem;
`;

const ResultCountContainer = styled.div`
  display: flex;
  align-items: end;
  gap: 0.5rem;
  margin-left: auto;
  margin-right: 1rem;
  align-items: center;
`;

const ResultCount = styled(Typography)`
  white-space: nowrap;
`;

const AwaitingOffice = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  // we never want the StatusDot to appear on its own line
  flex-wrap: nowrap;
`;

const AwaitingOfficeStatus = styled.span`
  white-space: nowrap;
`;

const StatusDot = styled.span`
  // min-width is required because the parent has flex
  min-width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: var(--red);
  vertical-align: middle;
`;

const StatusCell = ({
  statusInfo,
  rxId,
  fileHeader,
}: {
  statusInfo: StatusInfo;
  rxId: string;
  fileHeader?: string;
}) => {
  if (statusInfo.status === '') {
    return emptyTableCellCopy;
  }
  if (statusInfo.status === 'Awaiting office') {
    return (
      <AwaitingOffice>
        <AwaitingOfficeStatus>{statusInfo.status}</AwaitingOfficeStatus>
        <StatusDot />
      </AwaitingOffice>
    );
  } else {
    return (
      <StatusDownloadCell
        status={statusInfo.status}
        file_id={statusInfo.file_id}
        rx_id={rxId}
        file_header={fileHeader}
      />
    );
  }
};

const StatusDownloadCell: React.FC<{
  file_id: string | null;
  status: string;
  rx_id: string;
  file_header?: string;
}> = ({ file_id, status, rx_id, file_header }) => {
  const [isPreviewVisible, setPreviewVisible] = useState(false);

  if (!file_id) {
    return status;
  }

  const handleFilePreview = (e: React.MouseEvent) => {
    e.stopPropagation();
    setPreviewVisible(true);
  };

  const handleClosePreview = () => {
    setPreviewVisible(false);
  };

  return (
    // The preview and download components are both rendered, but only one is visible at a time
    // using CSS breakpoints.
    <>
      <PreviewFileContainer onClick={handleFilePreview}>
        {status}
        <Tooltip content="Open document" childNotInteractive={true}>
          <EyeIcon title="" />
        </Tooltip>

        {isPreviewVisible && (
          <FilePreviewModal
            fileUrl={getRxFilePreviewUrl(rx_id, file_id)}
            onClose={handleClosePreview}
            fileHeader={file_header}
            downloadUrl={getRxFileDownloadUrl(rx_id, file_id)}
          />
        )}
      </PreviewFileContainer>

      <DownloadFileContainer href={getRxFileDownloadUrl(rx_id, file_id)} download onClick={(e) => e.stopPropagation()}>
        {status}

        <Tooltip content="Download document" childNotInteractive={true}>
          <DownloadTray title="" />
        </Tooltip>
      </DownloadFileContainer>
    </>
  );
};

const RxTable: React.FC<RxTableProps> = ({
  data,
  tableProps,
  loading,
  initialSearchValue,
  initialFilterValues,
  handleDownload,
  downloadLoading,
}) => {
  const navigate = useNavigate();
  const [searchLoading, setSearchLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(loading);
  const [dataState, setDataState] = useState(data);

  const columns = [
    columnHelper.accessor('prescription_recieved_at', {
      header: 'Prescribed on',
      size: 100,
    }),
    columnHelper.accessor('prescriber_name', {
      header: 'Prescriber',
      size: 140,
    }),
    columnHelper.accessor('patient_name_and_dob', {
      header: 'Patient, DOB',
      size: 300,
    }),
    columnHelper.accessor('drug_name', {
      header: 'Drug',
      cell: (info) => (
        <Tooltip content={info.row.original.name} childNotInteractive={true}>
          <>{info.getValue()}</>
        </Tooltip>
      ),
      size: 100,
    }),
    columnHelper.accessor('pa_appeal_status', {
      header: 'PA',
      cell: (info) => <StatusCell statusInfo={info.getValue()} rxId={info.row.original.id} fileHeader="PA Letter" />,
      size: 140,
    }),
    columnHelper.accessor('bridge_status', {
      header: 'Enrollment',
      cell: (info) => (
        <StatusCell statusInfo={info.getValue()} rxId={info.row.original.id} fileHeader="Enrollment Form" />
      ),
      size: 140,
    }),
    columnHelper.accessor('pap_status', {
      header: 'PAP',
      cell: (info) => <StatusCell statusInfo={info.getValue()} rxId={info.row.original.id} fileHeader="PAP Letter" />,
      size: 140,
    }),
    columnHelper.accessor('pharmacy_status', {
      header: 'Pharmacy',
      cell: (info) => {
        const cellComponent = <StatusCell statusInfo={info.getValue()} rxId={info.row.original.id} />;
        if (info.getValue().status && info.row.original.pharmacy_address) {
          const addressString = getAddressString(info.row.original.pharmacy_address);
          return (
            <Tooltip content={addressString} childNotInteractive={true}>
              {cellComponent}
            </Tooltip>
          );
        }
        return cellComponent;
      },
      size: 140,
    }),
  ];

  const [searchValue, setSearchValue] = useState(initialSearchValue || '');

  // when the data is done loading, we want to reset the loading and data states
  useEffect(() => {
    if (!loading) {
      setSearchLoading(false);
      setPageLoading(false);
      setDataState(data);
    }
  }, [loading, data]);

  const table = useReactTable({
    // this data state ensures that the data doesn't disappear while it's being refreshed
    data: dataState,
    columns,
    state: {
      pagination: {
        pageIndex: tableProps.pageIndex,
        pageSize: tableProps.perPage,
      },
    },
    getCoreRowModel: getCoreRowModel(),
    pageCount: Math.ceil(tableProps.total / tableProps.perPage),
    manualPagination: true,
    columnResizeMode: 'onChange',
  });

  return (
    <TableContainer>
      <FilterBar>
        <FilterBarRow>
          <SearchContainer>
            <SearchInput
              label="Search by DOB, patient, etc."
              hideLabel={true}
              onChange={(e) => {
                setSearchLoading(true);
                const newValue = (e.target as HTMLInputElement).value;
                tableProps.submitSearchValue(newValue);
                setSearchValue(newValue);
              }}
              value={searchValue}
              $placement="left"
              loading={searchLoading && loading} // the searchLoading state will update a render cycle after the loading state
            />
          </SearchContainer>
          <TableMultiselectFilter
            options={tableProps.filters.offices}
            label={'Office'}
            horizontalAlign="right"
            initialValue={getDropdownInitialValueFromSearchParams(initialFilterValues?.office, 'rx', 'office_name')}
            onChange={(_, value: MultiselectAutocompleteOption[]) => {
              const selectedValuesList = value.map((valueItem) => valueItem.value);
              setPageLoading(true);
              tableProps.setOfficeFilter(selectedValuesList);
              updateTableColumnFilterValueCookie(value, 'rx', 'office_name');
            }}
          />
          <TableMultiselectFilter
            options={tableProps.filters.prescribers}
            label={'Prescriber'}
            horizontalAlign="right"
            // TODO: get initial value from cookie (if not in url)
            initialValue={getPrescriberDropdownInitialValueFromSearchParams(
              initialFilterValues?.prescriber,
              tableProps.filters.prescribers,
              'rx',
            )}
            onChange={(_, value: MultiselectAutocompleteOption[]) => {
              const selectedValuesList = value.map((valueItem) => valueItem.value);
              setPageLoading(true);
              tableProps.setPrescriberFilter(selectedValuesList);
              updateTableColumnFilterValueCookie(value, 'rx', 'prescriber_name');
            }}
          />
          <TableMultiselectFilter
            options={tableProps.filters.drugs}
            label={'Drug'}
            horizontalAlign="right"
            initialValue={getDropdownInitialValueFromSearchParams(initialFilterValues?.drug)}
            onChange={(_, value: MultiselectAutocompleteOption[]) => {
              const selectedValuesList = value.map((valueItem) => valueItem.value);
              setPageLoading(true);
              tableProps.setDrugNameFilter(selectedValuesList);
            }}
          />
        </FilterBarRow>
        <FilterBarRow>
          <FilterBadge
            onClick={() => {
              setPageLoading(true);
              tableProps.setBiologicsFilter(!initialFilterValues?.biologics);
            }}
            $active={!!initialFilterValues?.biologics}
          >
            Biologics
          </FilterBadge>
          {initialFilterValues?.overallStatus !== OverallStatusFilter.Completed && (
            <>
              <FilterBadge
                onClick={() => {
                  setPageLoading(true);
                  tableProps.setAwaitingOfficeFilter(!initialFilterValues?.awaitingOffice);
                }}
                $active={!!initialFilterValues?.awaitingOffice}
              >
                Awaiting office
              </FilterBadge>
              <FilterBadge
                onClick={() => {
                  setPageLoading(true);
                  tableProps.setAwaitingPatientFilter(!initialFilterValues?.awaitingPatient);
                }}
                $active={!!initialFilterValues?.awaitingPatient}
              >
                Awaiting patient
              </FilterBadge>
            </>
          )}
          <FilterBarRowSection>
            <FilterGroup>
              <FilterLabel
                $active={initialFilterValues?.overallStatus === OverallStatusFilter.InProgress}
                onClick={() => {
                  setPageLoading(true);
                  tableProps.setOverallStatusFilter(OverallStatusFilter.InProgress);
                }}
              >
                In Progress
              </FilterLabel>
              <FilterLabel
                $active={initialFilterValues?.overallStatus === OverallStatusFilter.Completed}
                onClick={() => {
                  setPageLoading(true);
                  tableProps.setOverallStatusFilter(OverallStatusFilter.Completed);
                }}
              >
                Completed
              </FilterLabel>
              <FilterLabel
                $active={initialFilterValues?.overallStatus === OverallStatusFilter.All}
                onClick={() => {
                  setPageLoading(true);
                  tableProps.setOverallStatusFilter(OverallStatusFilter.All);
                }}
              >
                All
              </FilterLabel>
            </FilterGroup>
            <ResultCountContainer>
              <ResultCount>
                {tableProps.total} {tableProps.total === 1 ? 'result' : 'results'}
              </ResultCount>
              <IconButton onClick={handleDownload} disabled={downloadLoading}>
                {downloadLoading ? <LoadingSpinner /> : <DownloadTray title="download" />}
              </IconButton>
            </ResultCountContainer>
          </FilterBarRowSection>
        </FilterBarRow>
      </FilterBar>
      <TableComponent>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHeader key={header.id} $width={`${header.getSize()}px`}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </TableHeader>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {pageLoading ? (
            <LoadingComponent table={table} />
          ) : dataState.length === 0 ? (
            <NoResultsComponent table={table} />
          ) : (
            table.getRowModel().rows.map((row) => (
              <ClickableRow
                key={row.id}
                onClick={() => navigate(`/rxs/${row.original.id}`)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    navigate(`/rxs/${row.original.id}`);
                  }
                }}
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id} $width={`${cell.column.getSize()}px`}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </ClickableRow>
            ))
          )}
        </tbody>
      </TableComponent>
      <Pagination
        table={table}
        goBack={() => {
          setPageLoading(true);
          tableProps.goBack();
        }}
        goForward={() => {
          setPageLoading(true);
          tableProps.goForward();
        }}
      />
    </TableContainer>
  );
};

export default RxTable;
