import styled from 'styled-components';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const Content = styled.div<{ $animateOut?: boolean }>`
  background-color: var(--white);
  height: 85%;
  width: 85%;
  overflow-y: scroll;
`;

export const Header = styled.div`
  padding: 0.75rem 1rem;
  border-bottom: 1px solid var(--border-gray);
  display: flex;
  justify-content: space-between;
  align-items: right;
`;

export const animationDurationMs = 10;
