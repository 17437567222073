import type { HTMLAttributes } from 'react';
import styled from 'styled-components';
import type { TippyProps } from '@tippyjs/react';
import Tippy from '@tippyjs/react';
import InfoIcon from '../../assets/icons/infoIcon.svg?react';
import { Typography } from '../Typography/Typography';
import { focusVisibleStyles, standardTransitionStyles } from '../../sharedStyles';

const InfoIconContainer = styled(InfoIcon)`
  display: inline-block;
  vertical-align: middle;
  cursor: help;
  width: 16px;
  height: 16px;
  color: var(--black);
`;

export const defaultTooltipIcon = <InfoIconContainer title="info" />;

const FocusableWrapper = styled.span`
  // stops icon children from being off-center
  display: flex;
  border-radius: var(--border-radius-medium);
  ${standardTransitionStyles}

  ${focusVisibleStyles}
`;

const TextContent = styled(Typography)`
  color: var(--white);
`;

const StyledTooltip = styled(Tippy)`
  padding: 0.5rem;
  background-color: var(--black);
  border-radius: var(--border-radius-small);
  // override default Tippy styles
  max-width: 600px !important;
  margin: 0 auto;
  // adding a border is helpful for windows high contrast mode
  border: 1px solid var(--black);

  // enables opacity fade animation
  &[data-state='hidden'] {
    opacity: 0;
  }
`;

// Full list of Tippy props: https://atomiks.github.io/tippyjs/v6/all-props/
export type TooltipProps = {
  // the element the tooltip appears above/next to
  children: React.ReactNode;
  // the content inside the tooltip
  content: React.ReactNode;
  appendTo?: 'parent' | Element | ((ref: Element) => Element);
  // If the child being passed into the Tooltip via the `children` prop is not interactive (e.g. a disabled button or an icon).
  // Please note that spacing and placement styling will need to be added to a wrapper around the Tooltip,
  // not on the child component inside the Tooltip, because there will be a wrapper around the child.
  childNotInteractive?: boolean;
  // Custom classname for additional styles.
  // These styles will only affect the tooltip bubble.
  className?: string;
  // Custom classname for the focusable wrapper that's placed around the child if childNotInteractive is true.
  focusableWrapperClassName?: string;
  // The trigger element the tooltip appears next to.
  // Use this instead of `children` if the trigger element is being stored in a ref.
  // Most cases will use `children` and not `reference`.
  reference?: React.RefObject<Element> | Element;
  // Whether the tooltip is always visible or not. Useful for testing.
  // This is most often left undefined so the Tooltip component controls if/when the
  // bubble appears (on hover, click, focus, etc).
  visible?: boolean;
  // Where the tooltip should be placed in relation to the element it's attached to.
  // See: https://atomiks.github.io/tippyjs/v6/all-props/#placement
  // Default is `"auto"`
  placement?: Extract<TippyProps['placement'], 'auto' | 'top' | 'right' | 'bottom' | 'left'>;
} & TippyProps &
  HTMLAttributes<HTMLElement>;

/**
 * A floating information box, attached to other components on the page. Used to display option, additional information.
 *
 * - https://atomiks.github.io/tippyjs/
 * - https://github.com/atomiks/tippyjs-react
 */
export const Tooltip = ({
  content,
  childNotInteractive,
  className,
  focusableWrapperClassName,
  placement = 'auto',
  ...rest
}: TooltipProps) => {
  let children = rest.children;
  // Tippy only works on elements with a tabindex. If the child is disabled, we need to
  // wrap it in an element with a tabindex in order for it to work.
  if (childNotInteractive) {
    children = (
      <FocusableWrapper
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        className={focusableWrapperClassName}
      >
        {rest.children}
      </FocusableWrapper>
    );
  }

  const textContent = (
    <TextContent renderedAs="span" styledAs="bodySmallDMSans">
      {content}
    </TextContent>
  );

  return (
    <StyledTooltip className={className} content={textContent} placement={placement} {...rest}>
      {children}
    </StyledTooltip>
  );
};
