import styled from 'styled-components';
import { Typography } from '../Typography/Typography';
import { pageLevelErrorCopy } from '../../sharedCopy';
import { breakpoints } from '../../tokens/breakpoints';

const StyledPageLoadError = styled.div`
  box-sizing: border-box;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
`;

const DesktopErrorMessage = styled(Typography)`
  display: none;

  @media (min-width: ${breakpoints.tabletMax}px) {
    display: block;
  }
`;

const MobileErrorMessage = styled(Typography)`
  display: block;

  @media (min-width: ${breakpoints.tabletMax}px) {
    display: none;
  }
`;

/**
 * Page-level error message for when the whole page content (minus the sidebar, topnav, page title) fails to load. Also works in tables that take up the full page.
 */
export const PageLoadError = ({ overrideErrorMessage }: { overrideErrorMessage?: string | null }) => {
  // the overrideErrorMessage should only be set if it's not null or undefined
  const errorMessage = overrideErrorMessage || pageLevelErrorCopy;
  return (
    <StyledPageLoadError>
      <DesktopErrorMessage styledAs="h6">{errorMessage}</DesktopErrorMessage>
      <MobileErrorMessage styledAs="bodyLargeSpaceGrotesk" weight={500}>
        {errorMessage}
      </MobileErrorMessage>
    </StyledPageLoadError>
  );
};
