import { Checkbox as MuiCheckbox } from '@mui/material';
import styled from 'styled-components';
import type { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox';
import Checked from 'design-system/src/assets/icons/checked.svg?react';
import Unchecked from 'design-system/src/assets/icons/unchecked.svg?react';
import { standardTransitionStyles, focusVisibleStylesNoWrapper } from '../../sharedStyles';

export type CheckboxProps = MuiCheckboxProps & {
  className?: string;
  hasError?: boolean;
};

const StyledCheckbox = styled(MuiCheckbox)`
  > svg {
    // custom border radius so the focus outline fits snugly around the icon
    border-radius: 1px;
    ${standardTransitionStyles}
  }

  &.Mui-focusVisible {
    > svg {
      ${focusVisibleStylesNoWrapper}
    }
  }
`;

export const Checkbox = ({ className, hasError, ...rest }: CheckboxProps) => {
  // We're checking for the checked state ourselves because the MUI Checkbox only updates the icon
  // and color to checked when the state is changed via the onChange prop. If we're updating the
  // check state through a different means, like when a different but related element is clicked,
  // the MUI Checkbox doesn't update the icon and color to checked automatically.
  const icon = rest.checked ? <Checked /> : <Unchecked />;

  const checkboxStyling = {
    color: hasError ? 'var(--red)' : rest.checked ? 'var(--purple)' : 'var(--disabled-gray)',
    backgroundColor: 'transparent',

    '&.Mui-checked': {
      color: 'var(--purple)',
    },

    '&.Mui-disabled': {
      color: 'var(--border-gray)',
      cursor: 'not-allowed',
      // required to make the cursor style work
      pointerEvents: 'auto',
    },
  };

  return (
    <StyledCheckbox
      // removes the ripple animation on click/focus
      disableRipple
      sx={{ ...checkboxStyling, ...rest.sx }}
      className={className}
      icon={icon}
      checkedIcon={icon}
      {...rest}
    />
  );
};
