import styled from 'styled-components';
import { DownloadFileButton } from '../DownloadFileButton/DownloadFileButton';

const StyledIframeWithToolbar = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 700px;
  gap: 4px;
`;

const StyledIframe = styled.iframe`
  flex: 1;
  height: 100%;
  border: 1px solid var(--border-gray);
`;

export type IframeWithToolbarProps = {
  fileUrl: string;
  iframeTitle?: string;
  downloadUrl?: string;
};

/**
 * Iframe for viewing pdfs and other image files. Has a toolbar above with an optional download button.
 */
export const IframeWithToolbar = ({ fileUrl, downloadUrl, iframeTitle }: IframeWithToolbarProps) => {
  return (
    <StyledIframeWithToolbar>
      {downloadUrl && <DownloadFileButton downloadUrl={downloadUrl} />}

      <StyledIframe src={fileUrl} title={iframeTitle} />
    </StyledIframeWithToolbar>
  );
};
