import styled from 'styled-components';
import { Typography } from 'design-system/src/components/Typography/Typography';
import { Hairline } from '../tableItemDetailViewComponents/Containers';
import React, { ReactNode } from 'react';
import { emptyTableCellCopy } from 'design-system/src/sharedCopy';

export const ExtraInfoTableWrapper = styled.div<{ $marginTop?: string }>`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin: 1.5rem 0;
  margin-top: ${({ $marginTop }) => $marginTop};
`;

export const CSSGridExtraInfoTableWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
`;

export const ExtraInfoTableFieldGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ExtraInfoTableFieldLabel = styled(Typography).attrs({ styledAs: 'bodySmallSpaceGrotesk' })<{
  $minWidth?: string;
}>`
  color: var(--dark-gray);
  min-width: ${({ $minWidth }) => $minWidth || '120px'};
  padding-right: 1rem;
`;

export type ExtraInfoTableProps = {
  data: Array<{ label: string; value: string | ReactNode | string[] | ReactNode[] | null }>;
  marginTop?: string;
  minFieldLabelWidth?: string;
};

/**
 * Table for displaying extra content at the top of the page.
 * Pass through a list to ensure the ordering of rows.
 */
export const ExtraInfoTable = ({ data, marginTop, minFieldLabelWidth }: ExtraInfoTableProps) => {
  return (
    <div>
      <ExtraInfoTableWrapper $marginTop={marginTop}>
        {data.map((item) => {
          // Default to empty table cell copy (e.g. '-') if value is null
          let value: string | React.ReactNode[] = emptyTableCellCopy;

          if (typeof item.value === 'string') {
            value = item.value;
          } else if (Array.isArray(item.value)) {
            value = item.value.map((valueItem) => {
              return <div key={`styled-extra-info-table-${valueItem}`}>{valueItem}</div>;
            });
          }

          return (
            <ExtraInfoTableFieldGroup key={item.label}>
              <ExtraInfoTableFieldLabel $minWidth={minFieldLabelWidth}>{item.label}</ExtraInfoTableFieldLabel>
              <Typography renderedAs="div">{value}</Typography>
            </ExtraInfoTableFieldGroup>
          );
        })}
      </ExtraInfoTableWrapper>

      <Hairline />
    </div>
  );
};
