export const createCookie = (name: string, value: string, expirationDays: number = 30) => {
  let expires = '';

  if (expirationDays) {
    const date = new Date();
    date.setTime(date.getTime() + expirationDays * 24 * 60 * 60 * 1000);
    expires = date.toUTCString();
  }

  document.cookie = `${name}=${value}; expires=${expires}; path=/`;
};

export const getCookie = (name: string) => {
  const nameEQ = `${name}=`;
  const cookieSegments = document.cookie.split(';');

  // using for loop so we can return out early when we find the cookie
  for (let index = 0; index < cookieSegments.length; index++) {
    const cookieSegment = cookieSegments[index].trim();

    if (cookieSegment.startsWith(nameEQ)) {
      // cookie found; return value
      return cookieSegment.substring(nameEQ.length, cookieSegment.length);
    }
  }

  // didn't find the cookie
  return null;
};

export const deleteCookie = (name: string) => {
  // set the cookie to expire in the past so it will be destroyed immediately
  createCookie(name, '', -1);
};
