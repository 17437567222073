import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Typography } from 'design-system/src/components/Typography/Typography';
import { AuthContext } from '../auth';
import { client } from '../api';
import { NavSidebar } from './NavSidebar';
import { TopNavbar } from './TopNavbar/TopNavbar';
import { OnboardingModal } from './OnboardingModal/OnboardingModal';

const Container = styled.div<{ $background: Background; $navbarPlacement: NavbarPlacement }>`
  background-color: ${({ $background }) =>
    $background === 'light' ? 'var(--light-surface);' : 'var(--extra-light-surface)'};
  min-height: 100vh;
  min-height: 100dvh; //iOS safari
  display: flex;
  flex-direction: ${({ $navbarPlacement }) => ($navbarPlacement === 'top' ? 'column;' : 'row')};
`;

const MainContent = styled.div<{ $background: Background; $navbarPlacement: NavbarPlacement; $noPadding?: boolean }>`
  flex: 1;
  ${({ $noPadding }) => ($noPadding ? null : 'padding: 1.25rem;')}
  margin-left: ${({ $navbarPlacement }) => ($navbarPlacement === 'top' ? '0;' : 'var(--nav-sidebar-width)')};
  background-color: ${({ $background }) =>
    $background === 'light' ? 'var(--light-surface);' : 'var(--extra-light-surface)'};
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

// light is used for logged out pages and extraLight is logged in pages
type Background = 'extraLight' | 'light';
type NavbarPlacement = 'side' | 'top';

interface PageContainerProps {
  title?: string;
  children: React.ReactNode;
  onBack?: () => void;
  centered?: boolean;
  background?: Background;
  navbarPlacement?: NavbarPlacement;
  // Removes padding from main content
  noPadding?: boolean;
}

const PageContainer: React.FC<PageContainerProps> = ({
  title,
  children,
  background = 'extraLight',
  navbarPlacement = 'side',
  noPadding,
}) => {
  const authContext = useContext(AuthContext);
  const [onboardingModalVisible, setOnboardingModalVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (authContext.user?.onboarding_modal.should_show && !onboardingModalVisible) {
      setOnboardingModalVisible(true);
    }
    if (!authContext.user?.onboarding_modal.should_show && onboardingModalVisible) {
      setOnboardingModalVisible(false);
    }
  }, [authContext]);

  const submit = useMutation({
    mutationFn: async () => {
      if (isSubmitting) {
        return;
      }
      setIsSubmitting(true);
      authContext.setUser({
        ...authContext.user!,
        onboarding_modal: {
          ...authContext.user!.onboarding_modal,
          should_show: false,
        },
      });

      return client
        .post('/onboarding-modal-view', {
          version: authContext.user?.onboarding_modal.version,
        })
        .then((res) => res.data);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['checkLogin'] });
      setIsSubmitting(false);
    },
  });

  return (
    <Container $background={background} $navbarPlacement={navbarPlacement}>
      {navbarPlacement === 'side' ? <NavSidebar /> : <TopNavbar />}
      <MainContent $background={background} $navbarPlacement={navbarPlacement} $noPadding={noPadding}>
        {title && (
          <TitleContainer>
            <Typography styledAs="h5" renderedAs="h1">
              {title}
            </Typography>
          </TitleContainer>
        )}
        {children}
        {onboardingModalVisible && (
          <OnboardingModal
            onClose={() => {
              setOnboardingModalVisible(false);
              submit.mutate();
            }}
          />
        )}
      </MainContent>
    </Container>
  );
};

export default PageContainer;
