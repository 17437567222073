import { Content, Overlay } from '../Modal';
import { Typography } from 'design-system/src/components/Typography/Typography';
import { Button } from '../Button/Button';
import styled from 'styled-components';
import { UseMutationResult } from '@tanstack/react-query';
import { Drug } from '../../api';
import { Patient } from '../../api';
import { ExtraInfoTable } from '../ExtraInfoTable/ExtraInfoTable';

const DeclineEnrollmentModal = styled(Content)`
  width: 600px;
  padding: 20px;
  border-radius: var(--border-radius-small);
  align-self: flex-start;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  height: fit-content;
`;

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1.5rem 0;
`;

const RadioOption = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  cursor: pointer;
`;

const RadioButton = styled.input`
  cursor: pointer;
  width: 20px;
  height: 20px;
  accent-color: var(--purple);
  margin-top: 0;
`;

// putting bodySmallDMSans styles directly here to override the radio label styles
const RadioLabel = styled(Typography)`
  cursor: pointer;
  font-weight: 400;
  font-size: 0.875rem;
`;

const ModalTitle = styled(Typography)`
  margin-bottom: 1.5rem;
`;

const CancelButton = styled(Button)`
  border: 1px solid var(--border-gray);
  background: transparent;
  transition: background-color 0.2s ease-out;
  color: var(--black);
  align-self: flex-end;

  &:hover {
    background: var(--light-border-gray);
  }
`;

const ButtonRow = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;

export type EnrollmentDeclineReason = 'already_enrolled' | 'internal' | 'should_not_be_enrolled' | 'other';

const enrollmentDeclineReasons: { label: string; value: EnrollmentDeclineReason }[] = [
  { label: 'This patient is already enrolled', value: 'already_enrolled' },
  { label: 'My practice will manage this enrollment internally', value: 'internal' },
  { label: 'This patient should not be enrolled', value: 'should_not_be_enrolled' },
  { label: 'Other', value: 'other' },
];

type EnrollmentDeclineProps = {
  closeModal: () => void;
  declineReason: EnrollmentDeclineReason;
  setDeclineReason: (reason: EnrollmentDeclineReason) => void;
  declineTask: UseMutationResult<void, Error, void>;
  patient: Patient;
  drug: Drug;
  handleDeclineSubmit: () => void;
};

const EnrollmentDeclineModal = ({
  closeModal,
  declineReason,
  setDeclineReason,
  declineTask,
  patient,
  drug,
  handleDeclineSubmit,
}: EnrollmentDeclineProps) => {
  const extraInfoData = [
    { label: 'Patient', value: `${patient?.first_name} ${patient?.last_name}` },
    { label: 'Drug', value: drug?.name },
  ];

  return (
    <Overlay>
      <DeclineEnrollmentModal onClick={(e) => e.stopPropagation()}>
        <ModalTitle styledAs="h6">Decline enrollment</ModalTitle>
        <ExtraInfoTable data={extraInfoData} marginTop="0" minFieldLabelWidth="60px" />

        <Typography styledAs="bodyDMSans" id="decline-enrollment-reason">
          Why are you declining enrollment?
        </Typography>

        <RadioGroup aria-labelledby="decline-enrollment-reason">
          {enrollmentDeclineReasons.map((reason) => (
            <RadioOption key={reason.value}>
              <RadioButton
                type="radio"
                id={reason.value}
                name="declineReason"
                value={reason.value}
                checked={declineReason === reason.value}
                onChange={() => setDeclineReason(reason.value)}
              />
              <RadioLabel as="label" htmlFor={reason.value}>
                {reason.label}
              </RadioLabel>
            </RadioOption>
          ))}
        </RadioGroup>

        <ButtonRow>
          <CancelButton onClick={closeModal}>Cancel</CancelButton>
          <Button
            onClick={handleDeclineSubmit}
            disabled={!declineReason || declineTask.isPending}
            isLoading={declineTask.isPending}
          >
            Submit
          </Button>
        </ButtonRow>
      </DeclineEnrollmentModal>
    </Overlay>
  );
};

export default EnrollmentDeclineModal;
