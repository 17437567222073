import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { StandardErrorToast } from 'design-system/src/components/Toast/Toast';
import { Checkbox } from 'design-system/src/components/Checkbox/Checkbox';
import { Typography } from 'design-system/src/components/Typography/Typography';
import { ErrorMessage } from 'design-system/src/components/ErrorMessage/ErrorMessage';
import { client } from '../../../api';
import { AcceptTermsFormData } from '../../../types';
import { Button } from '../../Button/Button';
import { PrivacyPolicy } from '../../PrivacyPolicy/PrivacyPolicy';
import { FormContainer } from '../FormContainer/FormContainer';
import { useSignupDocVariantsFromUrl } from '../useSignupDocVariantsFromUrl';
import { CSSGridExtraInfoTableWrapper, ExtraInfoTableFieldLabel } from '../../ExtraInfoTable/ExtraInfoTable';
import { Role } from '../../../types';
import { roles } from '../utils';

const StyledPrivacyPolicy = styled(PrivacyPolicy)`
  // The markdown automatically has some extra margin built-in
  // that's a little awkward in the context of this page
  margin-top: -1.5rem;
  margin-bottom: -1.5rem;
`;

const StyledAcceptTerms = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.625rem;
`;

const NoticeBlock = styled.div`
  margin: 1rem 1rem 0 1rem;
  padding: 1rem;
  background-color: var(--background-gray);
  border-radius: var(--border-radius-medium);
`;

const StyledCSSGridExtraInfoTableWrapper = styled(CSSGridExtraInfoTableWrapper)`
  margin: 1rem 0 0;
`;

const StyledExtraInfoTableFieldLabel = styled(ExtraInfoTableFieldLabel)`
  width: auto;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DocumentLink = styled.a`
  // TODO(design-system): use Link styles when available
  color: var(--black);
  font-weight: 500;
  text-decoration-color: var(--medium-gray);
  // Pushes the underline away from the text
  text-underline-offset: 3px;
`;

const SubmitButton = styled(Button)`
  display: flex;
  align-items: center;
  width: fit-content;
`;

export type AcceptTermsProps = {
  firstName: string;
  lastName: string;
  role: Role;
  practiceName: string;
  isSubmitting: boolean;
  onBack: () => void;
  onContinue: (formData: AcceptTermsFormData) => void;
  setUserSubmittedForm: (submitted: boolean) => void;
};

/**
 * The signup page where the user can accept the terms of service.
 */
export const AcceptTerms = ({
  firstName,
  lastName,
  role,
  practiceName,
  isSubmitting,
  onBack,
  onContinue,
  setUserSubmittedForm,
}: AcceptTermsProps) => {
  const [acceptCheckboxIsChecked, setAcceptCheckboxIsChecked] = useState(false);
  const [showError, setShowError] = useState(false);
  const { tosVariant, baaVariant, aaVariant } = useSignupDocVariantsFromUrl();

  // get the latest versions of the documents so we know which version the user is accepting
  // just in case another version is uploaded while the user is on this page
  const query = useQuery({
    queryKey: ['documentVersions'],
    queryFn: () => {
      return client
        .get(`/signup/document-versions?tos_variant=${tosVariant}&baa_variant=${baaVariant}&aa_variant=${aaVariant}`)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          toast.custom(() => <StandardErrorToast />);
        });
    },
  });

  useEffect(() => {
    if (acceptCheckboxIsChecked && showError) {
      setShowError(false);
    }
  }, [acceptCheckboxIsChecked]);

  const handleSubmit = () => {
    if (!query.data) {
      toast.custom(() => <StandardErrorToast />);
      return;
    }

    if (!acceptCheckboxIsChecked) {
      setShowError(true);
    } else {
      setUserSubmittedForm(true);
      onContinue({
        accepted_terms_at: new Date().toISOString(),
        tos_s3_key: tosVariant,
        tos_version_accepted: query.data.tos_version,
        baa_s3_key: baaVariant,
        baa_version_accepted: query.data.baa_version,
        aa_s3_key: aaVariant,
        aa_version_accepted: query.data.aa_version,
      });
    }
  };

  const urlSearchString = window.location.search;
  const standaloneTermsUrl = `/tos-provider${urlSearchString || ''}`;

  return (
    <FormContainer onBack={onBack}>
      <StyledAcceptTerms>
        <StyledPrivacyPolicy fileNames={[tosVariant, baaVariant, aaVariant]} />

        <NoticeBlock>
          <Typography>
            Please confirm that the information below is accurate, including your legal name, your role, and your
            Provider Entity's full legal name. If not, please go back and update the information.
          </Typography>

          <StyledCSSGridExtraInfoTableWrapper>
            <StyledExtraInfoTableFieldLabel>Your first name</StyledExtraInfoTableFieldLabel>
            <Typography>{firstName}</Typography>
            <StyledExtraInfoTableFieldLabel>Your last name</StyledExtraInfoTableFieldLabel>
            <Typography>{lastName}</Typography>
            <StyledExtraInfoTableFieldLabel>Your role</StyledExtraInfoTableFieldLabel>
            <Typography>{roles[role]}</Typography>
            <StyledExtraInfoTableFieldLabel>Provider Entity's legal name</StyledExtraInfoTableFieldLabel>
            <Typography>{practiceName}</Typography>
          </StyledCSSGridExtraInfoTableWrapper>
        </NoticeBlock>

        <CheckboxContainer>
          <Checkbox
            checked={acceptCheckboxIsChecked}
            onChange={() => setAcceptCheckboxIsChecked(!acceptCheckboxIsChecked)}
            aria-labelledby="accept-terms-tos-link"
          />
          <Typography id="accept-terms-tos-link">
            I have read, understand, and agree to be bound by{' '}
            <DocumentLink href={standaloneTermsUrl} target="_blank" rel="noopener noreferrer">
              all of the above Terms
              <span className="sr-only">Opens in a new tab</span>
            </DocumentLink>{' '}
            and agree to bind Provider Entity to{' '}
            <DocumentLink href={standaloneTermsUrl} target="_blank" rel="noopener noreferrer">
              these Terms
              <span className="sr-only">Opens in a new tab</span>
            </DocumentLink>
          </Typography>
        </CheckboxContainer>

        {/* This div stops flex gap from being applied to the button and its error message */}
        <div>
          <SubmitButton onClick={handleSubmit} isLoading={isSubmitting}>
            Agree and sign up
          </SubmitButton>

          <ErrorMessage visible={showError}>Please agree to the terms in order to continue.</ErrorMessage>
        </div>
      </StyledAcceptTerms>
    </FormContainer>
  );
};
