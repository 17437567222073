import { Modal, ModalTitle } from 'design-system/src/components/Modal';
import { IframeWithToolbar } from '../IframeWithToolbar/IframeWithToolbar';

const FilePreviewModal: React.FC<{
  fileHeader?: string;
  fileUrl: string;
  downloadUrl?: string;
  onClose: () => void;
}> = ({ fileHeader, fileUrl, downloadUrl, onClose }) => {
  return (
    <Modal onClose={onClose} fullHeight={true}>
      <ModalTitle>{fileHeader}</ModalTitle>

      <IframeWithToolbar fileUrl={fileUrl} downloadUrl={downloadUrl} iframeTitle={fileHeader} />
    </Modal>
  );
};

export default FilePreviewModal;
