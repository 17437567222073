import { useState, useEffect } from 'react';
import { client } from '../api';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';
import { IframeWithToolbar } from 'design-system/src/components/IframeWithToolbar/IframeWithToolbar';

export const PDFViewer: React.FC<{ pdfUrl: string }> = ({ pdfUrl }) => {
  const [pdfContent, setPdfContent] = useState<string | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [loadError, setLoadError] = useState(false);

  useEffect(() => {
    const fetchPdf = async () => {
      setIsFetching(true);

      try {
        const response = await client.get(pdfUrl, { responseType: 'blob' });
        const url = URL.createObjectURL(response.data);
        setPdfContent(url);
      } catch (error) {
        setLoadError(true);
      } finally {
        setIsFetching(false);
      }
    };

    fetchPdf();
  }, [pdfUrl]);

  if (loadError) {
    return <div>We're having trouble downloading the prior auth document. Please try again later.</div>;
  }

  if (!pdfContent || isFetching) {
    return <LoadingSpinner />;
  }

  return <IframeWithToolbar fileUrl={pdfContent} downloadUrl={pdfContent} />;
};
