import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { client } from '../api';
import PageContainer from './PageContainer';
import { LoadingSpinner } from 'design-system/src/components/LoadingSpinner/LoadingSpinner';
import { PageLoadError } from 'design-system/src/components/PageLoadError/PageLoadError';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      ['explo-dashboard']: any;
    }
  }
}

const DashboardEmbed: React.FC = () => {
  const { data, isLoading, error } = useQuery<{ jwt: string }>({
    queryKey: ['explo-jwt'],
    queryFn: () => {
      return client.post(`explo-jwt`).then((res) => res.data);
    },
  });
  let body;
  if (isLoading) body = <LoadingSpinner />;
  if (error) body = <PageLoadError />;
  if (data) {
    body = (
      <explo-dashboard
        dash-jwt={data.jwt}
        updateUrlParams={true}
        isProduction={true}
        environment="production"
        refresh-minutes={10}
        theme="tandem_portal"
      ></explo-dashboard>
    );
  }

  return <PageContainer title="Analytics">{body}</PageContainer>;
};

export default DashboardEmbed;
